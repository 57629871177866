.mainTerms, .mainTerms h1, .mainTerms h2 {
    text-align: left;
}

.mainTerms h2 {
    font-size: 24px;
}


.normalText {
opacity: 1;
}

.mainTerms h3 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5em;
    margin: 10px 0;
}

.mainTermsWrapper, .mainTermsWrapper div {
    margin: 40px 0;
}


