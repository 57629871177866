html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: '100vh';
}

:root {
  --main-blue-color: #1C3B6A;
  --main-pink-color: #EC1966;
}