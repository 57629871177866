.mainFaqs, .mainFaqs h1, .mainFaqs h2 {
    text-align: left;
}

.mainFaqs h1 {
    font-size: 30px;
}
.mainFaqs h2 {
    font-size: 24px;
}

.mainFaqsWrapper, .mainFaqsWrapper div {
    margin: 40px 0;
}


