
.main {
    display: flex;
    /* max-width: 1920px; */
    align-items: center;
    justify-content: center;
}

.aboutSection {
    display: flex;
    width: 100%;
    height: auto;
    padding: 40px 0;
    opacity: 1;
    text-align: center;
}

.subTitle {
    text-align: center;
    font-size: 36px;
    color: var(--main-blue-color);
}
.keyFeaturesSection {
    display: flex;
    width: 100%;
    height: auto;
    padding: 40px 0;
    opacity: 1;
    text-align: center;
}

.aboutSectionGraphs, .whatIsXfunSection, .downloadSection, .registerSection {
    margin: 40px 0;
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.downloadSection {
    background: #ebebeb;
}

.whatIsXfunSection img {
    width: 50%;
    height: 50%;
}

.mainWrapper {
    text-align: center;
}

h1, h2 {
    font-family: 'Rubik', sans-serif;
    text-align: center;
    font-weight:bold;
    font-size:64px;
    color: var(--main-blue-color);
    opacity: 1;
}

#about h2 {
    font-size: 36px;
    text-align: left;
}

.normalText {
    color: var(--main-blue-color);
    font-size: 18px;
    opacity: 0.72;
}

.text-left {
    text-align: left;
}


.keyFeaturesText {
    max-width: 800px;
    margin: 40px auto;
}

.keyFeaturesItem {
    display: flex;
    flex:1 auto;
    margin: 5px;
    text-align: left;
    color: var(--main-blue-color);
    font-size: 24px;
    align-items: center;
    justify-content: left;
    gap: 1.5rem;
    min-height: 100px;
}

.keyFeaturesItem img {
  width: 60px;
  height: 60px;
}

.downloadAppText, .registerInterestText {
    max-width: 800px;
    margin: 40px auto;
    text-align: center;
}

.downloadAppImages {
    display: flex;
    flex:1 auto;
    gap:1rem;
    align-items: center;
    justify-content: center;
}

.whatIsXfunSection img, .downloadAppImages img {
    padding: 20px;
}

@media (max-width: 768px) {
    h1, h2 {
        font-size:32px;
    }
    .subTitle {
        font-size:24px;
    }
    .keyFeaturesItem {
        font-size: 18px;
    }
    #about h2 {
        font-size: 24px;
    }
    .normalText {
        font-size: 14px;
    }
    .downloadAppImages img {
        max-width: 100%;
    }
    .downloadAppImages img {
        padding: 0;
    }
}
