#body {
    background: #181a19;
    margin: 0;
  }

  #rubic-widget-root {
    min-width: 100vw;
    min-height: 100vh;
  }

  #rubic-widget-root #rubic-widget-iframe {
    min-width: 100vw;
    min-height: 100vh;
    border-radius: 0px !important;
  }

  .iframe-content[_ngcontent-dcj-c271] {
    width: 100% !important;
  }