.footer {
    background-color: var(--main-blue-color);
    height: auto;
    padding: 10px 0;
}
@media (max-width: 768px) {
    .footer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 1rem;
    }
    .justify-content-center {
        gap: 2rem !important; 
    }
}

.footer-text{
    text-align: center;
    letter-spacing: 0px;
    color: #FFFF;
    opacity: 1;
    font-size: 14px;
}
