header {
    position: sticky;
    z-index: 100;
    background: #ffffff;
    box-shadow: 0 0 24px #00000014;
}

.header-container {
    height: 90px;
}

.header-text{
    text-align: center;
    letter-spacing: 0px;
    color: var(--main-blue-color) !important;
    opacity: 1;
    font-size: 16px;
}

.header-button{
    background-color: var(--main-pink-color);
    border-color: var(--main-pink-color);
}

.navbar {
    padding: 10px 20px;
    justify-content: space-between;
  }
  @media (min-width: 769px) {
    .navbar {
        max-width: 1260px;
        margin: 0 auto;
      }
  }  

.menu-buttons {
    width: 100%;
}

.navbarToggler {
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding: 0;
    line-height: 48px;
    text-align: center;
    background: none;
    border: none;
}
.navbarToggler svg {
    position: absolute;
    right: 0.5rem;
    text-align: center;
    width: 25px;
    color: var(--main-pink-color);
}
.navbarToggler:focus {
    box-shadow: none;
}
.navbarToggler:hover {
    color: white;
}
.navbarToggler.collapsed .navbarBars {
    visibility: visible;
    display: inherit;
}
.navbarToggler.collapsed .navbarTimes {
    visibility: hidden;
    display: none;
}
.navbarToggler:not(.collapsed) .navbarTimes {
    visibility: visible;
    display: inherit;
}
.navbarToggler:not(.collapsed) .navbarBars {
    visibility: hidden;
    display: none;
}

/* @media (max-width: 768px) {
    .menu-buttons .navbar-collapse.show {
        padding: 20px 0px;
    }
} */

@media (max-width: 768px) {
    .navbar-nav {
        padding: 20px 0px;
        flex-direction: column;
        align-items: flex-end;
    }
}

@media (min-width: 769px) {
    .navbar-expand-md .navbar-collapse {
        display: block !important;
    }
    .navbar-nav {
        flex-direction: row;
        float: right;
    }
}
