.mainPrivacy, .mainPrivacy h1, .mainPrivacy h2 {
    text-align: left;
}

.mainPrivacy h2 {
    font-size: 24px;
}

.mainPrivacyWrapper, .mainPrivacyWrapper div {
    margin: 40px 0;
}


